import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import CreateActivity from "../../components/master-form/CreateActivity";
import { Autocomplete, Button, Card, Container, Grid, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@mui/material";

export default function FormDetail() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const axios = useAxiosPrivate();
  const [name, setName] = useState("");
  const [category, setCategory] = useState(null);

  const [categories, setCategories] = useState([]);

  const [formDetail, setFormDetail] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchCategory, setSearchCategory] = useState("");
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);
  const [index, setIndex] = useState(null);

  const fetchData = async () => {
    try {
      console.log("fetchData");
      const res = await axios.get(`/master-form-approval/form/${id || ""}`);
      const data = res.data.data;
      setName(data.name);

      setCategory({
        value: data.categoryId,
        label: data.category.name,
      });
      setFormDetail(data.detail);
    } catch (err) {
      err.response?.data.message.reverse().forEach((message) => {
        toast.error(message);
      });
    }
  };

  const fetchCategory = async () => {
    try {
      const result = await axios.get(`/master-form-approval/category?search=${searchCategory}`);
      setCategories(
        result.data.data.results.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      );
      setLoading(false);
    } catch (err) {
      err.response.data.message.reverse().forEach((message) => {
        toast.error(message);
      });
    }
  };

  useEffect(() => {
    document.title = `${id ? "Edit" : "Create"} Form - ${process.env.REACT_APP_NAME}`;
    fetchCategory();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchCategory();
    // eslint-disable-next-line
  }, [searchCategory]);

  useEffect(() => {
    if (categories.length > 0) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [id, categories]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIndex(null);
    setData(null);
  };

  // const handleAdd = () => {
  //   setApprovalDetail((prev) => [
  //     ...prev,
  //     { edit: true, order: prev.length + 1, role: "" },
  //   ]);
  // };

  // const handleEditRow = (index) => {
  //   setApprovalDetail((prev) => {
  //     const newData = [...prev];
  //     newData[index].edit = true;
  //     return newData;
  //   });
  // };

  // const handleDeleteRow = (index) => {
  //   setApprovalDetail((prev) => {
  //     const newData = [...prev];
  //     newData.splice(index, 1);
  //     return newData;
  //   });
  // };

  const handleDelete = (index) => {
    setFormDetail((prev) => {
      const newData = [...prev];
      newData.splice(index, 1);
      return newData;
    });
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const data = {
        name,
        categoryId: category.value,
        detail: formDetail,
      };
      if (!id) {
        const res = await axios.post(`/master-form-approval/form`, data);
        res.data.message.reverse().forEach((message) => {
          toast.success(message);
        });
      } else {
        const res = await axios.put(`/master-form-approval/form/${id}`, data);
        res.data.message.reverse().forEach((message) => {
          toast.success(message);
        });
      }
      setLoading(false);

      navigate("/master-form-approval");
    } catch (err) {
      setLoading(false);
      err.response.data.message.reverse().forEach((message) => {
        toast.error(message);
      });
    }
  };

  // if (loading) return <Loading />

  return (
    <>
      <CreateActivity open={open} onClose={handleClose} data={data} index={index} setData={setFormDetail} />
      <h1>{`${id ? "Edit" : "Create"}`} Form</h1>
      <Container sx={{ width: "100vw" }}>
        <Card sx={{ padding: 5 }}>
          <Grid container justifyContent="space-between" spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                autoFocus
                margin="dense"
                name="name"
                label={t("component.text.name")}
                type="text"
                fullWidth
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                isOptionEqualToValue={(option, value) => option.value === value.value}
                onChange={(event, value) => setCategory(value)}
                onKeyUp={(event) => setSearchCategory(event.target.value)}
                id="combo-box-demo"
                value={category}
                options={categories}
                sx={{ width: "100%" }}
                renderInput={(params) => {
                  params.fullWidth = true;
                  params.margin = "dense";
                  params.required = true;
                  return <TextField {...params} label={t("component.text.category")} />;
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ marginTop: 2 }} justifyContent="flex-end">
            <Grid item xs={12}>
              <Typography variant="h6">Form Detail</Typography>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={handleOpen}>
                {t("component.button.addActivity")}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" colSpan={2}>
                      <strong>{t("component.text.activity")}</strong>
                    </TableCell>
                    <TableCell rowSpan={2} align="center" sx={{ width: "auto" }}>
                      <strong>{t("component.text.action")}</strong>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <strong>EN</strong>
                    </TableCell>
                    <TableCell align="center">
                      <strong>ID</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!formDetail.length && (
                    <TableRow>
                      <TableCell colSpan={3} align="center">
                        {t("component.text.noData")}
                      </TableCell>
                    </TableRow>
                  )}
                  {formDetail &&
                    formDetail.map((detail, index) => (
                      <TableRow key={index}>
                        <TableCell>{detail.activitiesEn}</TableCell>
                        <TableCell>{detail.activitiesId}</TableCell>
                        <TableCell>
                          <Grid container spacing={1} direction="row" alignItems="center" justifyContent="center">
                            <Grid item>
                              <Button
                                variant="contained"
                                size="small"
                                onClick={(e) => {
                                  setIndex(index);
                                  setData(detail);
                                  handleOpen();
                                  // onClick(e);
                                }}
                              >
                                {t("component.button.update")}
                              </Button>
                            </Grid>
                            <Grid item>
                              <Button variant="contained" size="small" color="danger" onClick={(e) => handleDelete(index)}>
                                {t("component.button.delete")}
                              </Button>
                            </Grid>
                            <Grid item></Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ marginTop: 2 }} justifyContent="center">
            <Grid item>
              <Button variant="contained" color="success" onClick={handleSubmit} disabled={loading}>
                {t("component.button.save")}
              </Button>
            </Grid>
            <Grid item>
              <Link to="/master-form-approval">
                <Button variant="contained" color="warning" disabled={loading}>
                  {t("component.button.cancel")}
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Card>
      </Container>
    </>
  );
}
